.profile-wrapper {
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-gap: 20px;
}

.edit-profile-footer {
  display: flex;
  flex-direction: row-reverse;
}

.edit-profile-footer button {
  margin-left: 20px;
}

.basic-details {
  display: grid;
  grid-gap: 15px;
  justify-items: center;
  align-items: center;
}

.basic-details .thumb {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.class-list {
  display: grid;
  grid-gap: 20px;
  border-left: 1px solid #c0c0c0;
}

.class-list .item {
  display: flex;
  align-items: center;
}

.class-list .badge {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-left: -4px;
  margin-right: 15px;
  background: #5eba00;
}

.class-list span {
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 5px;
  padding: 3px 10px;
}
