.master-container {
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-gap: 50px;
}

.nav-list a {
  text-decoration: none;
  outline: none;
}

.nav-list .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.nav-list .item .hover-icon {
  display: none;
}

.nav-list .item.active .hover-icon {
  display: block;
}

.card-label {
  padding: 0 20px;
}

.master-class-create {
  position: sticky;
  top: 0;
}

.hover-controls {
  display: flex;
  justify-content: space-between;
  border-left: 0 !important;
  border-right: 0 !important;
}
.hover-controls:first-child {
  border-top: 0 !important;
}

.hover-controls .controls {
  opacity: 0.5;
}

.hover-controls:hover .controls {
  opacity: 1;
}

.placeholder-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}
.placeholder-img img {
  width: 200px;
}
.placeholder-img .label {
  padding: 30px 0;
  width: 70%;
  text-align: center;
}

.hover-item:hover {
  cursor: pointer;
  background-color: aliceblue;
}
