body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a.color {
  color: #206bc4 !important;
  cursor: pointer;
}

.btn-brand {
  width: 100%;
  padding: 0 10px;
}
.btn-brand button {
  background-color: #9620d3 !important;
  border-color: #9620d3 !important;
  color: #fff;
}
