ul,
ol,
li {
  list-style: none;
}

.login-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.site-header {
  background: #fff;
  border-bottom: 1px solid #dce3e9;
}

.nav-header {
  position: sticky;
  top: 0;
  background: #fff;
  border-bottom: 1px solid #dce3e9;
  height: 70px;
  z-index: 99;
  overflow: hidden;
}
.nav-header .nav-link.active {
  padding-bottom: 12px;
  border-bottom: 3px solid #307fdd;
}
.nav-header .nav-link a {
  padding: 20px 0;
  text-decoration: none;
  color: #2a2a2a;
  outline: none;
}
.nav-header .nav-link:hover {
  padding-bottom: 20px;
  border-bottom: 3px solid #307fdd;
}

.header-panel {
  display: flex;
  justify-content: space-between;
}

.brand-panel {
  display: flex;
  justify-content: space-between;
}
.brand-panel .user-menu {
  cursor: pointer;
}
.brand-panel .user-menu:hover {
}

.nav-items .item {
  padding: 0px 10px;
}

.nav-tabs {
  padding: 10px 20px !important;
}

.card-footer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.card-footer button {
  margin-left: 20px;
}
.card-footer button:first-child {
  margin-left: 0;
}
/* tabler custom */
.page-title {
  color: #000;
}

.page-title .icon {
  margin-right: 15px;
}
a {
  text-decoration: none;
}

.logo {
  font-size: 20px;
  /* background: #0a9c58; */
  color: #fff;
  padding: 7px 20px;
  border-radius: 10px 0;
  cursor: pointer;
  transition: 0.3s ease-out all;
}

.logo img {
  max-width: 150px;
  max-height: 50px;
}

.logo_main img {
  max-width: 150px;
  max-height: 150px;
}

.logo:hover {
  /* background: #07bb67; */
  background: #f3f3f3;
}

.label-sup {
  display: flex;
}
.label-sup span {
  font-size: 0.6em;
  opacity: 0.6;
  padding: 3px 4px;
}
button.btn-secondary {
  background: #fff;
}
.card {
  /* overflow-x: auto; */
}

.card .card-status {
  height: 3px;
}
.list-group-item.active {
  background-color: #f0f4fb !important;
  border-color: rgba(0, 40, 100, 0.12) !important;
}

.footer-align-left {
  flex-direction: row;
}

.cursor {
  cursor: pointer;
}

.subject-pill {
  padding: 3px 8px;
  border-radius: 3px;
  color: #fff;
  font-size: 13px;
}
.subject-pill button {
  outline: 0;
  border: 0 !important;
  margin: 0 -8px !important;
  color: #fff !important;
}
.subject-pill button label {
  max-width: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 13px;
  margin: 0;
}

button.dropdown-toggle {
  margin-left: 10px;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  border: 2px solid #d2d2d2;
  background-color: #fff;
  outline: 0;
}

.class-name-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-bottom: 30px;
  background: #e8ecee;
}

.calendar-view_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  background-color: #fff;
  border-left: 1px solid #e8ecee;
}

.calendar-view_wrapper .head {
  padding: 18px 0;
  text-align: center;
  border-bottom: 1px solid #e8ecee;
  border-top: 1px solid #e8ecee;
  background-color: #e1eef3;
}

.calendar-view_wrapper .c-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid #e8ecee;
  border-right: 1px solid #e8ecee;
}
.calendar-view_wrapper .c-cell .active {
  background-color: #ebf7fd;
  font-weight: bold;
}
.calendar-view_wrapper .c-cell div {
  width: 100%;
  height: 100%;
  padding: 20px;
}
.calendar-view_wrapper .c-cell div:hover {
  background-color: #e8ecee;
  cursor: pointer;
}

.schedular-table {
  display: flex;
}

.schedular-table .day-col {
  width: 200px;
}

.schedular-table .day-col .day-header {
  padding: 10px 0;
  text-align: center;
}
.schedular-table .col-header.sub-col {
  display: flex;
  align-items: center;
  font-weight: bold;
  border-bottom: 1px solid #e1e1e1;
}
.schedular-table .col-header.sub-col:last-child {
  border-bottom: 0;
}

.schedular-table button.dropdown-toggle,
.schedular-table .sub-col {
  position: relative;
  height: 66px;
}

.schedular-table.dashboard .sub-col {
  height: 55px;
  text-align: center;
}

.schedular-table .sub-col .label {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 26px;
  text-align: center;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition: 0.2s ease all;
}

.schedular-table .sub-col-interval {
  padding: 10px 15px 0 0;
  text-align: center;
  border-top: 1px dashed #c0c0c0;
  border-bottom: 1px dashed #c0c0c0;
}

.schedular-table .sub-col-interval button {
  border: 0;
  margin-left: 0;
}

.schedular-table .sub-col-interval button .sub-col-interval-tutor {
  position: absolute;
  text-align: center;
  background-color: #d2d2d2;
  width: 100%;
}

.schedular-table .day-col button.dropdown-toggle {
  width: 100%;
  padding: 10px 0 25px;
  background: none;
  margin-top: -5px !important;
}

.ov-sc-row {
  display: grid;
  grid-template-columns: 120px 1fr;
  align-items: center;
}
.ov-sc-tutor-row {
  width: 120px;
}
.ov-sc-row-wrap {
  margin-bottom: 10px;
}

.ov-sc-tutor-col .ov-sc-col-name {
  height: 30px;
  text-align: center;
}

.ov-sc-tutor-col .ov-sc-class {
  height: 20px;
  font-weight: bold;
}

.ov-sc-row .ov-sc-slot-wrap {
  display: flex;
  align-items: center;
  /* width: 100%; */
  overflow: auto;
}

.ov-sc-row .ov-sc-slot-head {
  width: 113px;
  text-align: center;
  padding: 5px 5px;
  margin: 3px 7px;
  background: #e1e1e1;
  font-weight: bold;
  font-size: 12px;
}

.ov-sc-tutor-row .ov-sc-slot,
.ov-sc-row .ov-sc-slot {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 113px;
  height: 80px;
  margin: 5px 7px;
  border-radius: 3px;
  background: rgba(135, 150, 165, 0.064);
  overflow: hidden;
}
.common-dashboard-wrapper.hover .ov-sc-slot .label {
  bottom: -100%;
}

.dashboard-wrapper-tutor {
  display: flex;
}

.dashboard-wrapper-tutor .ov-sc-tutor-col {
  padding: 0 10px;
}
.dashboard-wrapper-tutor .ov-sc-tutor-col:not(:last-child) {
  border-right: 1px solid #2a2a2a;
}

.ov-sc-tutor-row .ov-sc-class {
  text-align: center;
}
.ov-sc-row .ov-sc-slot:hover .label {
  bottom: 0 !important;
}

.ov-sc-tutor-row .ov-sc-slot .title,
.ov-sc-row .ov-sc-slot .title {
  text-align: center;
  font-size: 13px;
  color: #fff;
}

.ov-sc-tutor-row .ov-sc-slot .label,
.ov-sc-row .ov-sc-slot .label {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 26px;
  text-align: center;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition: 0.2s ease all;
}
.accordion-head {
  padding: 10px 20px;
  background: #f0f4fb;
  border-radius: 3px;
  margin-bottom: 20px;
  cursor: pointer;
}

.accordion-body {
  max-height: 0;
  overflow: hidden;
  transition: 1s ease all;
}

.accordion-body.active {
  max-height: 100000px;
}

.add-sub-list {
  max-height: 300px;
  overflow: auto;
}

.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  padding: 2px;
  border-radius: 3px;
  max-height: 80vh;
  overflow: auto;
}
.popup-arrow {
  color: #2a2a2a;
}
[role="tooltip"].popup-content {
  width: 200px !important;
  min-width: 200px !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
  background: #2a2a2a !important;
  color: #fff;
  padding: 5px 10px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.2);
}
[data-popup="tooltip"].popup-overlay {
  background: transparent;
}
.modal {
  position: relative !important;
  display: block !important;
  max-width: 50%;
  min-width: 500px;
}
.modal .card {
  margin-bottom: 0 !important;
}
.modal .close-modal {
  position: absolute;
  right: -5px;
  top: -5px;
  width: 40px;
  background: #000;
  z-index: 1;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: #2a2a2a;
  color: #fff !important;
  cursor: pointer;
  opacity: 0.8 !important;
}

.common-status-card {
  position: relative;
}

.common-status-card .wrap-btn {
  position: absolute;
  right: 20px;
}

.container {
  max-width: 1200px !important;
}
