.title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.students-list {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-top: 0;
}

.filter-results {
  display: flex;
  flex-wrap: wrap;
}

.filter-results .search {
  margin-right: 30px;
  padding: 0 10px;
  @media and (min-width: 900px) {
    width: 500px;
  }
}

.view-student {
  display: flex;
  flex-direction: row-reverse;
}
